import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap,
  Screen,
  Visual
} from "./styles";

import bgHeader from "../../../../assets/works/interligados/bg-header.png";
import logoUnimed from "../../../../assets/works/interligados/unimed-logo-30.png";
import screen1 from "../../../../assets/works/interligados/screen-1.png";
import screen2 from "../../../../assets/works/interligados/screen-2.png";
import screen3 from "../../../../assets/works/interligados/screen-3.png";
import screen4 from "../../../../assets/works/interligados/screen-4.png";

import WOW from "wowjs";

export default class OrthoprideApp extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#3ADE59"} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <img
                src={logoUnimed}
                alt="Unimed"
                className="logoUnimed wow fadeInDown"
                data-wow-delay="200ms"
              />
              <h1 className="wow fadeIn" data-wow-delay="300ms">
                Interligados <br />
                Unimed
              </h1>
              <p className="wow fadeIn" data-wow-delay="300ms">
                Game Comemorativo
              </p>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Introdução
              </h2>
              <Wrap className="first wow fadeIn" data-wow-delay="200ms">
                <p>
                  A Unimed Volta Redonda completou 30 anos de atuação na cidade
                  e resolveu comemorar com uma série de ações de promoção da
                  marca para os públicos interno e externo da empresa. Entre as
                  ações de endomarketing, desenvolvemos o jogo “Interligado”, um
                  game-quiz comemorativo na qual os funcionários da empresa
                  respondiam a uma série de perguntas e competiam em equipes. As
                  equipes vencedoras ganhavam prêmios e brindes da empresa.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
            </Center>
            <Screen
              bg={screen1}
              className="wow fadeIn"
              data-wow-delay="150ms"
            />
            <Center>
              <h2 className="min wow fadeIn" data-wow-delay="100ms">
                Perguntas e Respostas
              </h2>
              <p className="min wow fadeIn" data-wow-delay="150ms">
                O game, em formato de quiz, traz perguntas relativas à cultura
                empresarial da Unimed e engaja os funcionários num aprendizado
                institucional.
              </p>
              <img
                src={screen2}
                alt="Interligados"
                className="screen wow fadeIn"
                data-wow-delay="300ms"
              />
            </Center>
            <Center>
              <h2 className="min wow fadeIn" data-wow-delay="100ms">
                Fases Variadas
              </h2>
              <p className="min wow fadeIn" data-wow-delay="150ms">
                As perguntas são divididas em fases e etapas, com múltiplos
                cenários.
              </p>
              <img
                src={screen3}
                alt="Interligados"
                className="screen wow fadeIn"
                data-wow-delay="300ms"
              />
            </Center>
            <Visual>
              <Center className="final">
                <h2 className="min wow fadeIn" data-wow-delay="100ms">
                  Identidade Visual
                </h2>
                <p className="min wow fadeIn" data-wow-delay="150ms">
                  Todos os cenários e elementos do game apresentam gráficos
                  consistentes e padronizados com a identidade da marca.
                </p>
                <img
                  src={screen4}
                  alt="Interligados"
                  className="screen wow fadeIn"
                  data-wow-delay="300ms"
                />
              </Center>
            </Visual>
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
