import React from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalStyle from "./styles/global";
import history from "./history";
import Routes from "./routes";

// import ReactGA from "react-ga";
// ReactGA.initialize("UA-717432-1");

export default function App() {
  return (
    <BrowserRouter forceRefresh={true} basename="/">
      <GlobalStyle />
      <Routes history={history} />
    </BrowserRouter>
  );
}
