import React, { useEffect, useState } from "react";

import api from "../../service/api";
import swal from "sweetalert";

import { Form, Input, FileInput } from "@rocketseat/unform";
import * as Yup from "yup";

import WOW from "wowjs";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  Container,
  Content,
  InnerBanner,
  ContentBanner,
  Title,
  Subtitle,
  Center,
  WrapForm,
} from "./styles";

import Elements from "../../assets/images/element-contact.png";

const iconError = <i className="fa fa-exclamation-triangle" />;

const schema = Yup.object().shape({
  name: Yup.string().required(iconError),
  email: Yup.string().email(iconError).required(iconError),
  phone: Yup.string().required(iconError),
  message: Yup.string(),
});

export default function Career() {
  const [loading, setLoading] = useState(false);
  const [attach, setAttach] = useState(undefined);

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  // const initialData = {
  //   name: "Bruno B Oliveira",
  //   email: "bbitencourt@gmail.com",
  //   phone: "(24) 99257-8859",
  //   message: "Olá"
  // };

  async function handleSubmit(data, { resetForm }) {
    if (!attach) {
      swal("Ops!", "Precisamos que envie seu currículo", "error");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("nome", data.name);
      formData.append("email", data.email);
      formData.append("telefone", data.phone);
      formData.append("mensagem", data.message);
      formData.append("arquivo", attach);

      const response = await api.post("/curriculos", formData, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });
      if (response.status === 200) {
        await setLoading(false);
        swal(
          "Enviado!",
          "Obrigado por querer fazer parte da equipe!",
          "success"
        );
        resetForm();
        return;
      }
      await setLoading(false);
      resetForm();
      swal("Ops!", "Algo deu errado, tente novamente.", "error");

      return;
    } catch (error) {
      await setLoading(false);
      swal("Ops!", "Algo deu errado, tente novamente.", "error");
    }
  }

  return (
    <Container>
      <Header color={"#FFF"} colorVem={"#3ADE59"} />
      <Content>
        <InnerBanner>
          <img src={Elements} alt="Elementos" className="el" />
          <ContentBanner>
            <Title>Faça Parte</Title>
          </ContentBanner>
        </InnerBanner>
        <Subtitle>
          <Center>
            <h2>
              Junte-se a uma equipe de criativos com ampla experiência em
              comunicação e projetos.
            </h2>
          </Center>
        </Subtitle>
        <WrapForm>
          <h3>Envie seu currículo</h3>

          <Form
            schema={schema}
            onSubmit={handleSubmit}
            // initialData={initialData}
          >
            <div className="wrap">
              <Input name="name" label="Nome" />
            </div>
            <div className="wrap">
              <Input name="email" label="E-mail" />
            </div>
            <div className="wrap">
              <Input name="phone" label="Telefone" />
            </div>
            <div className="wrap">
              <label>Currículo</label>
              <input
                name="attach"
                type="file"
                className="file"
                onChange={(e) => setAttach(e.target.files[0])}
              />
            </div>
            <div className="wrap full">
              <Input name="message" multiline label="Mensagem" />
            </div>
            <div className="center">
              <button type="submit">
                {loading ? (
                  <i className="fa fa-spinner fa-pulse" />
                ) : (
                  "Enviar Currículo"
                )}
              </button>
            </div>
          </Form>
        </WrapForm>

        <Footer />
      </Content>
    </Container>
  );
}
