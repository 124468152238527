import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const JobHeader = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 555px;
  ${props => props.bg && `background: url(${props.bg}) no-repeat center top`}
  background-attachment: fixed;

  @media screen and (max-width: 980px) {
    background-position-x: right;
  }
`;

export const ContentHeader = styled.div`
  width: 980px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 100px;

  @media screen and (max-width: 980px) {
    width: 80%;
  }

  h1 {
    color: #fff;
    font-size: 76px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -4px;
    span {
      color: #cb9c72;
      text-decoration: underline;
    }
  }
`;

export const ContentJob = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 88px;

  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 88px 32px;

    img {
      width: 100%;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #777777;
    padding-right: 64px;
  }

  h2 {
    color: #272727;
    margin-bottom: 32px;
  }

  h3 {
    color: #0d4e3b;
    margin-bottom: 32px;
    font-size: 21px;
    text-transform: uppercase;
    &.solo {
      margin-bottom: 0;
    }
  }
  .video {
    padding: 56.25% 0 0 0;
    position: relative;
    margin-bottom: 48px;

    .iFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Center = styled.div`
  width: 960px;
  display: flex;
  flex-direction: column;
  margin: 64px 0;
  position: relative;

  &:last-child {
    margin-top: 0;
  }

  @media screen and (max-width: 960px) {
    width: 100%;

    img.full {
      width: 100%;
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  ${props => props.posts && `flex-wrap: wrap`};
  justify-content: space-between;
  ${props => (props.posts ? `margin-bottom: 0` : `margin-bottom: 54px`)};
  position: relative;

  img.social {
    margin-bottom: 40px;
  }

  &.right {
    justify-content: flex-end;
  }
  &.column {
    flex-direction: column;
    flex: 0.5;
    @media screen and (max-width: 960px) {
      flex: 0.8;
    }
  }

  a {
    display: flex;
    align-items: center;
    color: #0d4e3b;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 600;

    i {
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;

    p {
      padding-right: 0;
    }

    a {
      position: absolute;
      right: 0;
      top: -60px;
    }
  }
`;
