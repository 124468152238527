import React, { useEffect } from "react";

import WOW from "wowjs";

import Header from "../../components/Header";
import Jobs from "../../components/Jobs";
import Instagram from "../../components/Instagram";
import Footer from "../../components/Footer";
import {
  Container,
  Content,
  InnerBanner,
  ContentBanner,
  Wrap,
  Title,
  Description,
  Subtitle,
} from "./styles";

export default function Works() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <Container>
      <Header color={"#FFF"} colorVem={"#3ADE59"} />
      <Content>
        <InnerBanner>
          <ContentBanner>
            <Wrap>
              <Title>Trabalhos</Title>
              <Description>Aproximando pessoas e marcas</Description>
            </Wrap>
          </ContentBanner>
        </InnerBanner>
        <Subtitle>
          <h1>
            Utilizamos tecnologia e boas ideias para juntar marcas, pessoas e
            plataformas.
          </h1>
        </Subtitle>
        <Jobs />
        {/* <Instagram /> */}
        <Footer />
      </Content>
    </Container>
  );
}
