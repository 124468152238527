import React, { useRef, useState, useCallback } from "react";
import Slider from "react-slick";

import {
  Container,
  Content,
  Banner,
  WrapText,
  Title,
  Graph,
  Separator,
  Subtitle,
  Arrows,
  Previous,
  Next,
} from "./styles";

import bnOrtho from "../../assets/banners/Banner-Ortho.png";
import bnOrthoMobile from "../../assets/banners/Banner-Ortho-mobile.png";
import bnUnimed from "../../assets/banners/Banner-Unimed.png";
import bnUnimedMobile from "../../assets/banners/Banner-Unimed-mobile.png";
import bnSupermee from "../../assets/banners/Banner-Supermee.png";
import bnSupermeeMobile from "../../assets/banners/Banner-Supermee-mobile.png";
import bnHalo from "../../assets/banners/Banner-halo.png";
import bnHaloMobile from "../../assets/banners/Banner-halo-mobile.png";

export default function Banners() {
  const slider = useRef(null);
  const [banners] = useState([
    {
      id: 1,
      url: bnHalo,
      urlMobile: bnHaloMobile,
      title: "Série Halo na Paramount+",
      titleColor: "#FFF",
      subTitle:
        "A Agência Foco ficou responsável pelo desenvolvimento do hotsite de lançamento da série no Brasil pela Paramount+",
      subTitleColor: "#FFF",
      graphColor: "#ff8429",
    },
    {
      id: 2,
      url: bnOrtho,
      urlMobile: bnOrthoMobile,
      title: "Garotos Orthopride",
      titleColor: "#FFF",
      subTitle:
        "A Foco está presente na nova campanha da franquia, com participação de Gabriel Medina e Caio Castro!",
      subTitleColor: "#FFF",
      graphColor: "#48BF51",
    },
    {
      id: 3,
      url: bnUnimed,
      urlMobile: bnUnimedMobile,
      title: "30 anos Unimed VR",
      titleColor: "#FFF",
      subTitle:
        "A Unimed Volta Redonda está comemorando 30 anos de boas memórias e a Foco faz parte desta comemoração!",
      subTitleColor: "#FFF",
      graphColor: "#FFA929",
    },
    {
      id: 4,
      url: bnSupermee,
      urlMobile: bnSupermeeMobile,
      title: "Supermee App Delivery",
      titleColor: "#FFF",
      subTitle:
        "Chegou um supermercado delivery em Volta Redonda! E a Foco participou desse projeto incrível.",
      subTitleColor: "#FFF",
      graphColor: "#48BF51",
    },
  ]);

  const next = useCallback(() => {
    slider.current.slickNext();
  });

  const prev = useCallback(() => {
    slider.current.slickPrev();
  });

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          bottom: "32px",
          left: 0,
          width: "100%",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };
  return (
    <Container>
      <Content>
        <Slider ref={slider} {...settings} className="gallery">
          {banners.map((banner) => (
            <Banner key={banner.id} url={banner.url} mobile={banner.urlMobile}>
              <WrapText>
                <Graph color={banner.graphColor} />
                <Title color={banner.titleColor}>{banner.title}</Title>
                <Separator color={banner.titleColor} />
                <Subtitle color={banner.subTitleColor}>
                  {banner.subTitle}
                </Subtitle>
              </WrapText>
            </Banner>
          ))}
        </Slider>
        <Arrows>
          <Previous onClick={prev} />
          <Next onClick={next} />
        </Arrows>
      </Content>
    </Container>
  );
}
