import { createGlobalStyle } from "styled-components";
import "font-awesome/css/font-awesome.css";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }
  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background:#fff;
    font-family: 'Chivo', sans-serif;
    color:#606060;
    font-weight: normal;
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
  }
  button {
    font-family: 'Poppins', sans-serif;
    border: 0;
    cursor: pointer;
  }
  input {
    font-family: 'Poppins', sans-serif;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
  }
  ul {
    list-style: none;
  }
`;

export default GlobalStyle;
