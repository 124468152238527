import styled from "styled-components";
import iconPrev from "../../assets/images/icon-arrow-left.svg";
import iconNext from "../../assets/images/icon-arrow-right.svg";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;

  .gallery {
    width: 1440px;
    height: 768px;
    @media only screen and (max-width: 980px) {
      width: 100% !important;
    }
  }
  .slick-dots {
    li button:before {
      font-size: 10px;
      line-height: 20px;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 20px;
      height: 20px;
    }
    li button:hover {
      color: #0d4e3b !important;
    }
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #0d4e3b;
  }
`;
export const Banner = styled.div`
  width: 1440px !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  max-height: 768px;
  ${props =>
    props.url && `background: url(${props.url}) no-repeat center center`};

  @media only screen and (max-width: 980px) {
    ${props =>
      props.mobile &&
      `background: url(${props.mobile}) no-repeat center center`};
    justify-content: center;
    width: 100% !important;
    height: 768px;
  }
`;

export const WrapText = styled.div`
  flex: 0.3;
  display: flex;
  flex-direction: column;
  margin-left: 96px;
  position: relative;

  @media only screen and (max-width: 980px) {
    margin: 0;
    flex: 0.8;
  }
`;

export const Title = styled.h1`
  font-size: 60px;
  ${props => (props.color ? `color: ${props.color}` : `color: #1e2623;`)}
  letter-spacing: -2px;
  line-height: 60px;
  position: relative;
  z-index: 10;
  @media only screen and (max-width: 980px) {
    text-align: center;
  }
`;

export const Graph = styled.span`
  position: absolute;
  top: 12%;
  left: -10%;
  z-index: 1;
  display: block;
  width: 337px;
  height: 29px;

  ${props =>
    props.color
      ? `background: ${props.color};`
      : `background: rgb(171, 217, 66);
  background: linear-gradient(
    130deg,
    rgba(171, 217, 66, 1) 0%,
    rgba(89, 191, 97, 1) 100%
  );`}
`;
export const Separator = styled.span`
  display: block;
  width: 110px;
  height: 1px;
  ${props =>
    props.color
      ? `border-bottom: 1px ${props.color} solid;`
      : `border-bottom: 1px #afb2b3 solid;`}
  margin: 32px 0;

  @media only screen and (max-width: 980px) {
    align-self: center;
  }
`;

export const Subtitle = styled.p`
  font-family: "Chivo", sans-serif;
  font-size: 20px;
  ${props => (props.color ? `color: ${props.color}` : `color: #606060;`)}

  @media only screen and (max-width: 980px) {
    text-align: center;
  }
`;
export const Arrows = styled.div`
  position: absolute;
  bottom: 32px;
  right: 32px;
  @media only screen and (max-width: 980px) {
    display: none;
  }
`;
export const Previous = styled.button`
  width: 48px;
  height: 48px;
  background: url(${iconPrev}) no-repeat center center;

  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 0.3;
  }
`;
export const Next = styled.button`
  width: 48px;
  height: 48px;
  background: url(${iconNext}) no-repeat center center;

  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 0.3;
  }
`;
export const BallsControl = styled.div`
  position: absolute;
  bottom: 32px;
  left: 0;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Ball = styled.button`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  ${props => (props.active ? `background:#0D4E3B` : `background:#ACACAC`)};
  margin-right: 8px;

  &:last-child {
    margin: 0;
  }
`;
