import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const JobHeader = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 555px;

  ${props =>
    props.footer
      ? `
  background: rgb(232,173,40);
background: linear-gradient(130deg, rgba(232,173,40,1) 0%, rgba(221,114,58,1) 100%);
  `
      : `
  background: rgb(24, 73, 108);
  background: linear-gradient(
    130deg,
    rgba(24, 73, 108, 1) 0%,
    rgba(36, 38, 62, 1) 100%
  );

  `}

  ${props =>
    props.footer &&
    `margin-bottom: 400px;`}

  @media screen and (max-width: 640px) {
    height: 340px;
    div {
      padding-top: 54px;
    }
  }
  @media screen and (max-width: 414px) {
    height: 290px;
  }
`;

export const ContentHeader = styled.div`
  width: 980px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 150px;
  align-items: center;
  position: relative;
  p {
    color: #fff;
  }
  img {
    position: absolute;
    &.element {
      top: 10%;
    }
    &.notebook {
      bottom: -35%;
    }
  }
  h1 {
    color: #fff;
    font-size: 64px;
    font-weight: 600;
    line-height: 70px;
    letter-spacing: -3px;
    text-align: center;
    span {
      color: #cb9c72;
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 980px) {
    width: 80%;
    img {
      position: absolute;
      &.element {
        top: 10%;
      }
      &.notebook {
        width: 110%;
      }
    }
  }
  @media screen and (max-width: 640px) {
    img {
      position: absolute;
      &.element {
        display: none;
      }
    }
    h1 {
      font-size: 48px;
      line-height: 58px;
    }
  }
`;

export const ContentJob = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => (props.gray ? `margin-top: 0` : `margin-top: 270px`)};
  ${props => (props.gray ? `padding: 88px 0` : `padding: 0`)};
  ${props =>
    props.gray ? `background-color: #F3F3F3` : `background-color: transparent`};

  h2 {
    color: #272727;
    margin-bottom: 32px;
  }

  img {
    margin-top: 48px;
    border-radius: 16px;
    box-shadow: 0 3px 24px rgba(0, 0, 0, 0.25);
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    ${props => (props.gray ? `padding: 88px 32px` : `padding: 0 32px`)};

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 414px) {
    ${props => (props.gray ? `margin-top: 0` : `margin-top: 160px`)};
  }
`;

export const Center = styled.div`
  width: 960px;
  display: flex;
  flex-direction: column;
  position: relative;
  ${props => props.line && `border-top: 1px #cccccc solid`};
  padding: 48px 0;

  @media screen and (max-width: 960px) {
    width: 100%;

    img.full {
      width: 100%;
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 54px;
  position: relative;

  p {
    font-size: 16px;
    line-height: 24px;
    color: #777777;
    padding-right: 64px;
  }

  a {
    display: flex;
    align-items: center;
    color: #0d4e3b;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 600;

    i {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;

    p {
      padding-right: 0;
    }

    a {
      position: absolute;
      right: 0;
      top: -60px;
    }
  }
`;
