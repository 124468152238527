import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #272727;
  padding: 72px 0;
`;
export const Content = styled.div`
  width: 100%;
  max-width: 950px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const About = styled.div`
  width: 265px;
  padding: 0 16px;
  @media screen and (max-width: 980px) {
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
  }

  p {
    font-size: 14px;
    color: #c7c7c7;
    line-height: 1.5em;
  }
`;
export const Logo = styled.div`
  margin-bottom: 32px;
`;
export const SiteMap = styled.div`
  flex: 1;
  padding: 0 16px;
`;
export const Menu = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;

  @media screen and (max-width: 980px) {
    display: none;
  }

  a {
    color: #fff;
    padding: 0 10px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.6s ease;
    &:first-child {
      padding-left: 0;
    }
    &:hover {
      opacity: 0.5;
    }
    &:active {
      opacity: 0.3;
    }
  }
`;
export const Contact = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 32px;
  }
`;
export const Phones = styled.div`
  p {
    font-family: "Poppins", sans-serif;
    color: #afafaf;
    font-weight: 300;
    font-size: 16px;
    i {
      margin-right: 16px;
    }
    @media screen and (max-width: 980px) {
      float: left;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
`;
export const Email = styled.div`
  font-family: "Poppins", sans-serif;
  color: #afafaf;
  font-weight: 300;
  font-size: 16px;
  i {
    margin-right: 16px;
  }
`;
export const Social = styled.div`
  padding: 0 16px;

  a {
    padding: 5px 16px;
    color: #fff;

    &:hover {
      opacity: 0.5;
    }
    &:active {
      opacity: 0.3;
    }
  }
`;
