import React, { useEffect, useState } from "react";

import api from "../../service/api";
import swal from "sweetalert";
import { Form, Input } from "@rocketseat/unform";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";

import ReactMapGL, { Marker } from "react-map-gl";

import WOW from "wowjs";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  Container,
  Content,
  InnerBanner,
  ContentBanner,
  Title,
  Subtitle,
  Center,
  WrapForm,
  Location,
  Map,
  Pin,
} from "./styles";

import Elements from "../../assets/images/element-contact.png";

const TOKEN =
  "pk.eyJ1IjoiZm9jb2NvbXVuaWNhY2FvIiwiYSI6ImNqeHA5dXA2MjBmazMzYnA5dnRjeHRnd3kifQ.AmRrcNghNEmQOMaTN05f5A";

const iconError = <i className="fa fa-exclamation-triangle" />;

const schema = Yup.object().shape({
  name: Yup.string().required(iconError),
  email: Yup.string().email(iconError).required(iconError),
  company: Yup.string().required(iconError),
  message: Yup.string().required(iconError),
});

export default function Contact() {
  const [viewport, setViewport] = useState({
    latitude: -22.505247,
    longitude: -44.09094,
    zoom: 14,
    bearing: 0,
    pitch: 0,
  });
  const [telefone, setTelefone] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  // const initialData = {
  //   name: "Bruno B Oliveira",
  //   email: "bbitencourt@gmail.com",
  //   company: "Foco",
  //   message: "Olá"
  // };

  async function handleSubmit(data, { resetForm }) {
    if (!telefone) {
      swal("Ops!", "Precisamos que preencha seu telefone", "error");
      return;
    }
    if (telefone.length < 14) {
      swal(
        "Ops!",
        "Precisamos que preencha seu telefone corretamente",
        "error"
      );
      return;
    }
    try {
      setLoading(true);
      const post = {
        nome: data.name,
        empresa: data.company,
        email: data.email,
        telefone: telefone,
        mensagem: data.message,
      };
      const response = await api.post("/contacts", post, {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (response.status === 200) {
        await setLoading(false);
        swal("Enviado!", "Em breve entraremos em contato", "success");
        await setTelefone(null);
        resetForm();

        return;
      }
      await setLoading(false);
      swal("Ops!", "Algo deu errado, tente novamente.", "error");
      return;
    } catch (error) {
      await setLoading(false);
      swal("Ops!", "Algo deu errado, tente novamente.", "error");
    }
  }

  return (
    <Container>
      <Header color={"#FFF"} colorVem={"#3ADE59"} />
      <Content>
        <InnerBanner>
          <img src={Elements} alt="Elementos" className="el" />
          <ContentBanner>
            <Title>Contato</Title>
          </ContentBanner>
        </InnerBanner>
        <Subtitle>
          <Center>
            <h2>
              Fale com a gente
              <br />
              sobre seu projeto
            </h2>
            <p>(24) 3345 0549 • contato@fococomunicacao.com</p>
          </Center>
        </Subtitle>
        <WrapForm>
          <p>Ou mande-nos uma mensagem. {telefone && telefone.length}</p>
          <Form
            schema={schema}
            onSubmit={handleSubmit}
            // initialData={initialData}
          >
            <div className="wrap">
              <Input name="name" label="Nome" />
            </div>
            <div className="wrap">
              <Input name="company" label="Empresa" />
            </div>
            <div className="wrap">
              <Input name="email" label="E-mail" />
            </div>
            <div className="wrap">
              <label>Telefone</label>
              <MaskedInput
                value={telefone}
                guide={false}
                onChange={(e) => setTelefone(e.target.value)}
                mask={[
                  "(",
                  /[1-9]/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            </div>
            <div className="wrap full">
              <Input name="message" multiline label="Mensagem" />
            </div>
            <div className="center">
              <button type="submit">
                {loading ? (
                  <i className="fa fa-spinner fa-pulse" />
                ) : (
                  "Enviar Mensagem"
                )}
              </button>
            </div>
          </Form>
        </WrapForm>
        <Location>
          <Center>
            <h3>Onde Estamos</h3>
            <p>
              Rua Desembargador Cesar Salamonte, 111 Aterrado - Volta Redonda/RJ
              • CEP 27.213-190
            </p>
          </Center>
          <Map>
            <ReactMapGL
              width={"100%"}
              height={452}
              {...viewport}
              mapStyle="mapbox://styles/fococomunicacao/cjxup1pyp0rs31ctcpixkv3nk"
              mapboxApiAccessToken={TOKEN}
              scrollZoom={true}
            >
              <Marker
                latitude={-22.505247}
                longitude={-44.09094}
                offsetLeft={-20}
                offsetTop={-10}
              >
                <Pin />
              </Marker>
            </ReactMapGL>
          </Map>
        </Location>
        <Footer />
      </Content>
    </Container>
  );
}
