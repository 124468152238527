import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";

import { Container, Content, Logo, Menu, ButtonMenu, Submenu } from "./styles";
import logo from "../../assets/images/logo-foco-menor.svg";

export default function Header({ reduce, color, colorVem }) {
  const [transform, setTransform] = useState(false);
  const [open, setOpen] = useState(false);

  const submenu = useRef();

  function handleClick() {
    setOpen(!open);
  }

  function handleClickOutside(event) {
    if (submenu.current && !submenu.current.contains(event.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY < 60 && !reduce) {
      setTransform(false);
    } else {
      setTransform(true);
    }
  }, [reduce]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (reduce) {
      setTransform(true);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, reduce]);

  return (
    <>
      <Container bg={transform}>
        <Content reduzir={transform}>
          <Link to="/" className="logo-link">
            <Logo reduzir={transform}>
              <img src={logo} alt="Foco" />
            </Logo>
            {transform && (
              <span>
                Voe {!transform && <br />}
                mais alto
              </span>
            )}
          </Link>
          <Menu reduzir={transform} color={color} colorVem={colorVem}>
            <Link to="/agencia">Agência</Link>
            <Link to="/trabalhos">Trabalhos</Link>
            <Link to="/servicos">Serviços</Link>
            <Link to="/contato">Contato</Link>
            <Link to="/vem-pra-foco" className="vem">
              #vemprafoco
            </Link>
            <ButtonMenu onClick={() => handleClick()} />
          </Menu>
        </Content>
      </Container>
      <Submenu ref={submenu} open={open}>
        <li>
          <Link to="/agencia">Agência</Link>
        </li>
        <li>
          <Link to="/trabalhos">Trabalhos</Link>
        </li>
        <li>
          <Link to="/servicos">Serviços</Link>
        </li>
        <li>
          <Link to="/contato">Contato</Link>
        </li>
        <li>
          <Link to="/vem-pra-foco" className="vem">
            #vemprafoco
          </Link>
        </li>
      </Submenu>
    </>
  );
}
