import React, { useEffect, useRef, useCallback } from "react";

import Slider from "react-slick";
import { formatDistanceToNow } from "date-fns";
import pt from "date-fns/locale/pt";

import WOW from "wowjs";

import Header from "../../components/Header";
import Recognition from "../../components/Recognition";
import Footer from "../../components/Footer";
import {
  Container,
  Content,
  InnerBanner,
  ContentBanner,
  WrapText,
  Title,
  Description,
  ControlsSlider,
  Prev,
  Next,
  About,
  TextTime,
  Center,
  Team,
  Member,
  DescrTeam,
  Name,
  TextFinal
} from "./styles";

import El01 from "../../assets/images/elements-agency-1.png";
import El02 from "../../assets/images/elements-agency-2.png";
import El03 from "../../assets/images/elements-agency-3.png";
import iPhone from "../../assets/images/mockup-iphone.png";
import foto1 from "../../assets/agency/1.jpg";
import foto2 from "../../assets/agency/2.jpg";
import foto3 from "../../assets/agency/3.jpg";
import foto4 from "../../assets/agency/4.jpg";
import foto5 from "../../assets/agency/5.jpg";
import foto6 from "../../assets/agency/6.jpg";
import foto7 from "../../assets/agency/7.jpg";
import foto8 from "../../assets/agency/8.jpg";
import davi from "../../assets/team/davi.png";
import bruno from "../../assets/team/bruno.png";
import alcenor from "../../assets/team/alcenor.png";
import lanuza from "../../assets/team/lanuza.png";

export default function Agency() {
  const slider = useRef(null);

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const next = useCallback(() => {
    slider.current.slickNext();
  }, []);

  const prev = useCallback(() => {
    slider.current.slickPrev();
  }, []);

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <Container>
      <Header color={"#FFF"} colorVem={"#3ADE59"} />
      <Content>
        <InnerBanner>
          <img
            src={El01}
            alt="Foco"
            className="element-1 wow fadeIn"
            data-wow-delay="200ms"
          />
          <img
            src={El02}
            alt="Foco"
            className="element-2 wow fadeIn"
            data-wow-delay="200ms"
          />
          <img
            src={El03}
            alt="Foco"
            className="element-3 wow fadeIn"
            data-wow-delay="100ms"
          />
          <ContentBanner className="wow fadeIn" data-wow-delay="300ms">
            <WrapText>
              <Title>Agência</Title>
              <Description>
                O que importa para nós é o desafio de comunicar.
              </Description>
            </WrapText>
            <Slider ref={slider} {...settings} className="gallery">
              <img src={foto1} alt="Agência Foco" />
              <img src={foto2} alt="Agência Foco" />
              <img src={foto3} alt="Agência Foco" />
              <img src={foto4} alt="Agência Foco" />
              <img src={foto5} alt="Agência Foco" />
              <img src={foto6} alt="Agência Foco" />
              <img src={foto7} alt="Agência Foco" />
              <img src={foto8} alt="Agência Foco" />
            </Slider>
            <ControlsSlider>
              <Prev onClick={prev}>
                <i className="fa fa-angle-left" />
              </Prev>
              <Next onClick={next}>
                <i className="fa fa-angle-right" />
              </Next>
            </ControlsSlider>
          </ContentBanner>
        </InnerBanner>
        <About>
          <Center>
            <img
              src={iPhone}
              alt="Foco"
              className="iPhone wow fadeInLeft"
              data-wow-delay="200ms"
            />
            <TextTime className="wow fadeIn" data-wow-delay="200ms">
              <span className="line" />
              <h1>
                {formatDistanceToNow(new Date("2001/12/21"), {
                  locale: pt,
                  addSuffix: true
                })}
                , inovando e crescendo junto com o mundo digital.
              </h1>
              <p>
                A Foco nasceu com o objetivo de crescer junto com o mundo
                digital que surgia diante de nós. Crescemos e nos tornamos uma
                agência diferente de como você conhece. Nascemos conectados.
                Somos uma equipe de publicitários, jornalistas, programadores,
                planejadores, designers e criativos com experiência em diversas
                áreas da comunicação e com uma visão ampla do que o universo
                digital tem para oferecer.
              </p>
            </TextTime>
          </Center>
          <h2 className="wow fadeIn" data-wow-delay="200ms">
            Quem Somos
          </h2>
          <Team>
            <DescrTeam className="wow fadeIn" data-wow-delay="100ms">
              <p>
                Somos uma equipe de publicitários, jornalistas, programadores,
                planejadores e criativos com experiência em diversas áreas da
                comunicação.
                <br />
                <span />
              </p>
            </DescrTeam>
            <Member className="wow fadeIn" data-wow-delay="200ms">
              <img src={davi} alt="Davi Tedesco" />
              <Name>
                <h3>Davi Tedesco</h3>
                <p>Jornalista e Publicitário</p>
              </Name>
            </Member>
            <Member className="wow fadeIn" data-wow-delay="300ms">
              <img src={bruno} alt="Bruno Bitencourt" />
              <Name>
                <h3>Bruno Bitencourt</h3>
                <p>Bacharel em Ciência da Computação</p>
              </Name>
            </Member>
            <Member className="wow fadeIn" data-wow-delay="400ms">
              <img src={lanuza} alt="Lanuza Madruga" />
              <Name>
                <h3>Lanuza Madruga</h3>
                <p>Jornalista</p>
              </Name>
            </Member>
            <Member className="wow fadeIn" data-wow-delay="500ms">
              <img src={alcenor} alt="Alcenor Tedesco" />
              <Name>
                <h3>Alcenor Tedesco</h3>
                <p>Arquiteto e Publicitário </p>
              </Name>
            </Member>
          </Team>
          <TextFinal className="wow fadeIn" data-wow-delay="200ms">
            <p>
              Temos pressa, somos interativos e buscamos resultados. Sejam esses
              resultados on ou off-line. Porque o que importa para nós é o
              desafio de comunicar. Acreditamos que marcas devem ter uma conexão
              mais profunda, contínua e verdadeira com seus admiradores e
              consumidores. Para isto, utilizamos tecnologia e boas ideias para
              juntar marcas, pessoas e plataformas, onde quer que elas estejam.
            </p>
          </TextFinal>
        </About>
        <Recognition />
        <Footer />
      </Content>
    </Container>
  );
}
