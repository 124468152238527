import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap,
  DesignSystem,
  DesignItem,
  Screens
} from "./styles";

import bgHeader from "../../../../assets/works/orthoprideapp/bg-header.png";
import icons from "../../../../assets/works/orthoprideapp/icons.png";
import elements from "../../../../assets/works/orthoprideapp/elements.png";
import fonts from "../../../../assets/works/orthoprideapp/fonts.png";
import colors from "../../../../assets/works/orthoprideapp/colors.png";
import tela1 from "../../../../assets/works/orthoprideapp/tela1.png";
import tela2 from "../../../../assets/works/orthoprideapp/tela2.png";
import tela3 from "../../../../assets/works/orthoprideapp/tela3.png";
import slide1 from "../../../../assets/works/orthoprideapp/slide1.png";
import slide2 from "../../../../assets/works/orthoprideapp/slide2.png";
import slide3 from "../../../../assets/works/orthoprideapp/slide3.png";
import slide4 from "../../../../assets/works/orthoprideapp/slide4.png";
import slide5 from "../../../../assets/works/orthoprideapp/slide5.png";

import WOW from "wowjs";

export default class OrthoprideApp extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    const settings = {
      dots: false,
      arrows: false,
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToScroll: 1,
      slidesToShow: 4,
      speed: 1000,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 640,
          settings: {
            centerMode: false,
            slidesToShow: 1
          }
        },
        {
          breakpoint: 414,
          settings: {
            centerMode: false,
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#3ADE59"} reduce={true} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <h1 className="wow fadeInDown" data-wow-delay="100ms">
                Novo App <br />
                Pacientes
              </h1>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Introdução
              </h2>
              <Wrap className="first wow fadeIn" data-wow-delay="200ms">
                <p>
                  A Orthopride é uma franquia de clínicas odontológicas com
                  atuação em mais de 8 estados, atendendo mais de 100 mil
                  pacientes no país e com previsão de expansão para 180 clínicas
                  ativas até 2020. O carro chefe da franquia são os aparelhos
                  ortodônticos, mirando em um público adolescente e jovem
                  adulto, que permanece em contato com a clínica durante todos
                  os meses necessários ao tratamento. Pensando nisso,
                  desenvolvemos um app que facilitasse ao cliente o agendamento
                  de consultas, atendimento e o pagamento de faturas a longo
                  prazo.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
            </Center>
            <DesignSystem>
              <Center>
                <h3 className="wow fadeIn" data-wow-delay="100ms">
                  Design system
                </h3>
                <p className="min wow fadeIn" data-wow-delay="150ms">
                  Elaboramos uma interface consistente e padronizada, de acordo
                  com os critérios da marca e briefing apresentado.
                </p>
                <Wrap className="wrap">
                  <DesignItem className="wow fadeIn" data-wow-delay="100ms">
                    <h4>Cores:</h4>
                    <img src={colors} alt="Cores" className="design" />
                  </DesignItem>
                  <DesignItem className="wow fadeIn" data-wow-delay="150ms">
                    <h4>Tipografia:</h4>
                    <img src={fonts} alt="Topografia" className="design" />
                  </DesignItem>
                  <DesignItem className="wow fadeIn" data-wow-delay="200ms">
                    <h4>Ícones:</h4>
                    <img src={icons} alt="Ícones" className="design" />
                  </DesignItem>
                  <DesignItem className="wow fadeIn" data-wow-delay="250ms">
                    <h4>Elementos:</h4>
                    <img src={elements} alt="Elementos" className="design" />
                  </DesignItem>
                </Wrap>
              </Center>
            </DesignSystem>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="100ms">
                Pagamento Facilitado
              </h2>
              <p className="min wow fadeIn" data-wow-delay="150ms">
                Interface que facilita o pagamento de faturas e quitação de
                débitos dos pacientes com a franquia.
              </p>
              <img
                src={tela1}
                alt="Tela"
                className="screen wow fadeIn"
                data-wow-delay="200ms"
              />
            </Center>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="100ms">
                Reagendamento de Consultas
              </h2>
              <p className="min wow fadeIn" data-wow-delay="150ms">
                Seleção de novas datas e horários para os tratamentos agendados,
                de acordo com disponibilidade da clínica.
              </p>
              <img
                src={tela2}
                alt="Tela"
                className="screen wow fadeIn"
                data-wow-delay="100ms"
              />
            </Center>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="100ms">
                Auto-atendimento
              </h2>
              <p className="min wow fadeIn" data-wow-delay="150ms">
                Solução de dúvidas e problemas simples dos pacientes através de
                chat automático (Chatbot).
              </p>
              <img
                src={tela3}
                alt="Tela"
                className="screen wow fadeIn"
                data-wow-delay="100ms"
              />
            </Center>
            <Screens>
              <Slider
                ref={c => (this.slider = c)}
                {...settings}
                className="gallery wow fadeIn"
                data-wow-delay="100ms"
              >
                <div className="item">
                  <img src={slide3} alt="Tela" />
                </div>
                <div className="item">
                  <img src={slide1} alt="Tela" />
                </div>
                <div className="item">
                  <img src={slide2} alt="Tela" />
                </div>
                <div className="item">
                  <img src={slide4} alt="Tela" />
                </div>
                <div className="item">
                  <img src={slide5} alt="Tela" />
                </div>
              </Slider>
            </Screens>
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
