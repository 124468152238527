import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InnerBanner = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #272727;
  overflow: hidden;
  height: 100vh;
  max-height: 768px;
  color: #fff;
  position: relative;

  img {
    &.element-1 {
      position: absolute;
      top: 15%;
      left: -5%;
    }
    &.element-2 {
      position: absolute;
      top: 10%;
      right: 5%;
    }
    &.element-3 {
      position: absolute;
      top: 40%;
      right: 8%;
      z-index: 1;
    }
    @media screen and (max-width: 640px) {
      display: none;
    }
  }
`;

export const ContentBanner = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 100px;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 980px) {
    width: 80%;
  }
  @media screen and (max-width: 640px) {
    align-items: center;
    flex-direction: column;
  }
  .gallery {
    width: 732px;
    @media screen and (max-width: 980px) {
      width: 100% !important;
      img {
        height: 100%;
      }
    }
  }
`;

export const WrapText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 50%;
  bottom: 20%;
  z-index: 1;

  @media screen and (max-width: 980px) {
    right: 65%;
  }

  @media screen and (max-width: 640px) {
    position: relative;
    right: auto;
    left: auto;
    top: auto;
    bottom: auto;
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-size: 80px;
  line-height: 100px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 24px;
  letter-spacing: -3px;
  @media screen and (max-width: 640px) {
    max-width: 100%;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  color: #fff;
  text-align: left;
  max-width: 60%;
  @media screen and (max-width: 640px) {
    max-width: 100%;
    margin-bottom: 32px;
  }
`;

export const ControlsSlider = styled.div`
  display: flex;
  justify-content: space-between;
  width: 102px;
  height: 43px;
  position: absolute;
  right: 5%;
  bottom: 25%;
  z-index: 1;

  @media screen and (max-width: 980px) {
    right: 3%;
  }
  @media screen and (max-width: 640px) {
    right: 3%;
  }
`;

export const Prev = styled.button`
  font-size: 22px;
  width: 43px;
  height: 43px;
  transition: all 0.4s ease;
  background: transparent;
  border: 2px solid;
  color: rgba(255, 255, 255);
  &:hover {
    background: #fff;
    color: black;
    border: none;
  }
`;

export const Next = styled(Prev)``;

export const About = styled.section`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    color: #d5d5d5;
    font-size: 90px;
    margin-bottom: 64px;
    letter-spacing: -6px;
  }
  @media screen and (max-width: 640px) {
    h2 {
      display: block;
      font-size: 50px;
      letter-spacing: -4px;
      text-align: center;
    }
  }
`;

export const Center = styled.section`
  max-width: 980px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 270px 0 120px 0;
  position: relative;

  img.iPhone {
    top: 20%;
    left: -10%;
    position: absolute;
    margin-right: 54px;
  }

  @media screen and (max-width: 980px) {
    img.iPhone {
      display: none;
    }
    justify-content: center;
    padding-left: 48px;
    padding-right: 48px;
  }
`;

export const TextTime = styled.div`
  position: relative;
  flex: 0.5;

  @media screen and (max-width: 980px) {
    flex: 1;
  }

  span {
    display: block;
    background: #707070;
    width: 1px;
    height: 100px;
    position: absolute;
    top: -164px;
    left: 87px;
  }

  h1 {
    color: #272727;
    font-size: 29px;
    line-height: 36px;
    margin-bottom: 48px;
  }

  p {
    color: #919191;
    font-size: 16px;
    line-height: 28px;
  }
`;

export const Team = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px;
  list-style: none;
  margin-bottom: 96px;

  @media screen and (max-width: 640px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Member = styled.li`
  width: 20%;
  display: flex;
  flex-direction: column;
  border-left: 1px #d1d1d1 solid;
  padding: 42px 0;

  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media screen and (max-width: 640px) {
    width: 80%;
    padding: 0;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 350px;
  }
`;

export const Name = styled.div`
  padding: 24px 32px;

  h3 {
    color: #0d4e3b;
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 5px;
    font-weight: 600;
  }

  p {
    color: #8d8d8d;
    font-size: 16px;
  }
`;

export const DescrTeam = styled(Member)`
  p {
    display: flex;
    flex-direction: column;
    background: #272727;
    color: #ccc;
    height: 350px;
    align-items: flex-start;
    justify-content: center;
    font-size: 14px;
    line-height: 24px;
    padding: 0 48px;
  }
  span {
    display: block;
    width: 40px;
    height: 4px;
    background: #3ade59;
    margin-top: 16px;
  }
`;
export const TextFinal = styled.div`
  max-width: 500px;
  margin: 0 auto 96px auto;

  p {
    color: #919191;
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (max-width: 980px) {
    padding: 0 48px;
  }
`;
