import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import WOW from "wowjs";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  Container,
  Content,
  InnerBanner,
  ContentBanner,
  Wrap,
  Title,
  Description,
  Service,
  WrapContent,
  WrapBoxes,
  Ads,
  Project,
  ServiceDetails,
  WrapItems,
  Item,
} from "./styles";

import iconTv from "../../assets/images/icon-television.png";
import iconPhone from "../../assets/images/icon-smartphone.png";
import iconVisual from "../../assets/images/icon-visual.png";
import iconSocial from "../../assets/images/icon-redessociais.png";
import iconTvRadio from "../../assets/images/icon-television-radio.png";
import iconImprensa from "../../assets/images/icon-printed.png";
import iconDesktop from "../../assets/images/icon-desktop.png";
import iconIphone from "../../assets/images/icon-iphone.png";
import iconBrowser from "../../assets/images/icon-browser.png";
import note from "../../assets/images/note-soul.png";

export default function Services() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <Container>
      <Header color={"#FFF"} colorVem={"#3ADE59"} />
      <Content>
        <InnerBanner>
          <ContentBanner>
            <Wrap>
              <Title>Serviços</Title>
              <Description>
                O que importa para nós é o desafio de comunicar.
              </Description>
            </Wrap>
          </ContentBanner>
        </InnerBanner>
        <Service>
          <WrapContent>
            <h1 className="wow fadeIn" data-wow-delay="100ms">
              Temos pressa, somos interativos e buscamos resultados. Sejam esses
              resultados on ou off-line.
            </h1>
            <WrapBoxes>
              <Ads className="wow fadeIn" data-wow-delay="150ms">
                <span />
                <img src={iconTv} alt="Publicidade" className="icon" />
                <h2>Publicidade</h2>
                <p>
                  Nossa equipe é formada por profissionais graduados,
                  especializados em comunicação e atuantes na área desde 1998.
                  Estamos preparados para oferecer a melhor solução em
                  propaganda para sua marca. Entendemos que a publicidade deve
                  ser pensada de forma criativa, planejada estrategicamente e
                  principalmente focada na busca de resultados. Nossa equipe
                  está pronta para gerir sua marca trabalhando diretamente o seu
                  público alvo.
                </p>
              </Ads>
              <Project className="wow fadeIn" data-wow-delay="200ms">
                <span />
                <img src={iconPhone} alt="Projetos" className="icon" />
                <h2>Sites, Aplicações web e aplicativos</h2>
                <p>
                  Com profissionais graduados e especializados no
                  desenvolvimento de tecnologias, podemos construir o ambiente
                  perfeito para sua empresa, seja em websites, e-commerces,
                  aplicativos, ou até mesmo um completo sistema para gerir o seu
                  negócio. Seja qual for a sua necessidade, desenvolvemos de
                  forma rápida, segura e de fácil utilização e gerenciamento.
                  Além de total dedicação na criação de interfaces atraentes e
                  modernas, com ênfase na facilidade de uso para plataformas web
                  ou mobile (iOS e Android).
                </p>
              </Project>
            </WrapBoxes>
          </WrapContent>
        </Service>
        <ServiceDetails>
          <WrapContent>
            <WrapItems>
              <img
                src={note}
                alt="Serviços"
                className="wow fadeInRightBig bg-service-details"
                data-wow-delay="150ms"
              />
              <Item className="wow fadeIn" data-wow-delay="100ms">
                <img src={iconVisual} alt="Branding" className="icon" />
                <h3>Branding</h3>
                <p>
                  Sua marca pensanda de forma ampla e com foco em resultados.
                </p>
              </Item>
              <Item className="wow fadeIn" data-wow-delay="200ms">
                <img
                  src={iconSocial}
                  alt="Gestão de Redes Sociais"
                  className="icon"
                />
                <h3>Gestão de Redes Sociais</h3>
                <p>
                  Esteja próximo de quem realemente interessa para o seu
                  negócio, seus clientes.
                </p>
              </Item>
              <Item className="wow fadeIn" data-wow-delay="100ms">
                <img
                  src={iconTvRadio}
                  alt="Campanhas Publicitárias"
                  className="icon"
                />
                <h3>Campanhas Publicitárias</h3>
                <p>
                  Leve seua marca até as pessoas. A única forma de uma empresa
                  se comunicar é através de publicidade. Desenvolvimento de
                  campanha on-line ou off-line.
                </p>
              </Item>
              <Item className="wow fadeIn" data-wow-delay="200ms">
                <img
                  src={iconImprensa}
                  alt="Assessoria de Imprensa"
                  className="icon"
                />
                <h3>Assessoria de Imprensa</h3>
                <p>
                  Através da credibilidade de nossos profissionais, colocamos a
                  sua marca na mídia de forma positiva. Gerimos o relacionamento
                  de sua empresa com a impressa.
                </p>
              </Item>
            </WrapItems>
            <WrapItems className="web">
              <Item className="wow fadeIn web" data-wow-delay="200ms">
                <img
                  src={iconDesktop}
                  alt="Desenvolvimento de Sites"
                  className="icon"
                />
                <h3>Desenvolvimento de Sites</h3>
                <p>
                  Criamos sites performáticos utilizando as mais avançadas
                  tecnologias do mercado, mesmas tecnologias utilizadas por
                  empresas como NetFlix, Nubank e Twitter.
                </p>
              </Item>
              <Item className="wow fadeIn web" data-wow-delay="300ms">
                <img src={iconIphone} alt="Aplicativos" className="icon" />
                <h3>Aplicativos</h3>
                <p>
                  Desenvolvemos aplicativos Nativos para Android e iOS com a
                  mesma tecnologia utilizada por empresas como Facebook,
                  Instagram, Uber, Skype e Pinterest.
                </p>
              </Item>
              <Item className="wow fadeIn web" data-wow-delay="400ms">
                <img
                  src={iconBrowser}
                  alt="Desenvolvimento de Sistemas"
                  className="icon"
                />
                <h3>Desenvolvimento de Sistemas</h3>
                <p>
                  Temos know how adquirido por anos de desenvolvimento. Estamos
                  prontos para criar qualquer tipo de produto utilizando as
                  melhores tecnologias do mercado.
                </p>
              </Item>
            </WrapItems>
            <Link
              to="/trabalhos"
              className="wow fadeIn moreWorks"
              data-wow-delay="200ms"
            >
              Veja nossos trabalhos
            </Link>
          </WrapContent>
        </ServiceDetails>
        <Footer />
      </Content>
    </Container>
  );
}
