import React from "react";

import { Container, Content, Title, List, Item } from "./styles";

export default function Recognition() {
  return (
    <Container className="wow fadeIn" data-wow-delay="300ms">
      <Content>
        <Title>
          Reconhecimento<span>.</span>
        </Title>
        <List>
          <Item>
            <i className="fa fa-trophy" />
            <div className="text">
              <h4>Prêmio Talentos da Publicidade</h4>
              <span className="category">17° Edição</span>
              <p>
                Recebemos o 1º lugar como agência, na categoria “Campanha”, com
                a propaganda institucional do Sider Shopping: Suas histórias são
                as nossas histórias.
              </p>
            </div>
          </Item>
          <Item>
            <i className="fa fa-trophy" />
            <div className="text">
              <h4>
                Três bons motivos para comemorar no Talentos da Publicidade
              </h4>
              <span className="category">16º Edição</span>
              <p>
                Fomos premiados em três categorias juntos com o Sider Shopping.
                1º lugar na categoria Varejo com a campanha de Natal e em 3º
                lugar nas categorias Varejo e Mercado com a campanha do Dia dos
                Namorados e com a campanha do Dia das Mães, respectivamente.
              </p>
            </div>
          </Item>
          <Item>
            <i className="fa fa-trophy" />
            <div className="text">
              <h4>PJB – Prêmio Jovem Brasileiro</h4>
              <span className="category">2013-2018</span>
              <p>
                Somos a única agência de comunicação do estado do Rio de
                Janeiro, que desde o ano de 2013, recebemos o título de “Empresa
                Amiga do Jovem”, no PJB em São Paulo, pelo apoio e incentivo aos
                jovens do país.
              </p>
            </div>
          </Item>
        </List>
      </Content>
    </Container>
  );
}
