import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap
} from "./styles";

import bgHeader from "../../../../assets/works/frilat/bg-header.png";
import social1 from "../../../../assets/works/frilat/social1.png";
import social2 from "../../../../assets/works/frilat/social2.png";
import social3 from "../../../../assets/works/frilat/social3.png";
import social4 from "../../../../assets/works/frilat/social4.png";
import social5 from "../../../../assets/works/frilat/social5.png";
import social6 from "../../../../assets/works/frilat/social6.png";
import social7 from "../../../../assets/works/frilat/social7.png";
import social8 from "../../../../assets/works/frilat/social8.png";
import folder from "../../../../assets/works/frilat/folder.jpg";

import WOW from "wowjs";

export default class Frilat extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#3ADE59"} />
        <Content>
          <JobHeader bg={bgHeader}>
            <ContentHeader>
              <h1 className="wow fadeInDown" data-wow-delay="100ms">
                Frilat
              </h1>
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                A Frilat
              </h2>
              <Wrap className="wow fadeIn" data-wow-delay="200ms">
                <p>
                  Desde 1982 no mercado de delicatessen, a Frilat leva à mesa de
                  seus clientes produtos que são sinônimo de qualidade. Estamos
                  com eles desde 2006 e buscamos levar aos seus clientes,
                  informação de forma simples e atraente. Focamos sua
                  comunicação no mercado varejista tanto no on quanto no
                  off-line.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
              <h3 className="wow fadeIn solo" data-wow-delay="150ms">
                Posts
              </h3>
            </Center>
            <Center>
              <Wrap posts>
                <img
                  src={social1}
                  alt="Post"
                  className="social wow fadeIn"
                  data-wow-delay="150ms"
                />
                <img
                  src={social2}
                  alt="Post"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social3}
                  alt="Post"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social4}
                  alt="Post"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social5}
                  alt="Post"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social6}
                  alt="Post"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social7}
                  alt="Post"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
                <img
                  src={social8}
                  alt="Post"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
              </Wrap>
              <Center>
                <h3>Encarte de Natal</h3>
                <img
                  src={folder}
                  alt="Cartaz Pedalada"
                  className="social wow fadeIn"
                  data-wow-delay="200ms"
                />
              </Center>
            </Center>
          </ContentJob>
          <Footer />
        </Content>
      </Container>
    );
  }
}
