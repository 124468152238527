import styled from "styled-components";
import BgWorks from "../../assets/images/bg-works.png";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const InnerBanner = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: black;
  overflow: hidden;
  height: 555px;
  color: #fff;
  background-image: url(${BgWorks});
`;
export const ContentBanner = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 50px;
  width: 980px;
  margin: 0 auto;

  @media screen and (max-width: 980px) {
    width: 80%;
  }
`;
export const Wrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export const Title = styled.h1`
  @keyframes typing {
    from {
      max-width: 0;
    }
    to {
      max-width: 100%;
    }
  }
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #2ad94b;
    }
  }
  font-size: 80px;
  line-height: 100px;
  font-weight: 600;
  letter-spacing: -3px;
  animation: typing 2s steps(40, end), blink-caret 1s step-end infinite;
  border-right: 4px solid;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
`;
export const Description = styled.p`
  @keyframes slideInRight {
    from {
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }
  font-size: 16px;
  color: #fff;
  max-width: 25%;
  animation: slideInRight 0.95s ease;

  @media screen and (max-width: 640px) {
    max-width: 100%;
    text-align: center;
    margin-top: 32px;
  }
`;
export const Subtitle = styled.div`
  width: 950px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  padding-bottom: 64px;
  border-bottom: 1px #d5d5d5 solid;

  h1 {
    color: #272727;
    font-size: 26px;
    line-height: 32px;
    width: 60%;
    align-self: flex-start;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    padding: 0 48px 64px 48px;
    border: 0;
    h1 {
      width: 100%;
    }
  }
`;
