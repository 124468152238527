import React, { Component } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import {
  Container,
  Content,
  JobHeader,
  ContentHeader,
  ContentJob,
  Center,
  Wrap
} from "./styles";

import ElementHeader from "../../../../assets/works/webcol/elements-header.png";
import ElementFooter from "../../../../assets/works/webcol/elements-footer.png";
import Notebook from "../../../../assets/works/webcol/note-site.png";
import Notebook2 from "../../../../assets/works/webcol/note-site-2.png";
import screen1 from "../../../../assets/works/webcol/screen1.png";
import screen2 from "../../../../assets/works/webcol/screen2.png";

import WOW from "wowjs";

export default class WebCol extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Container>
        <Header color={"#FFF"} colorVem={"#FFF"} />
        <Content>
          <JobHeader>
            <ContentHeader>
              <img
                src={ElementHeader}
                alt="Graph"
                className="element wow fadeIn"
                data-wow-delay="200ms"
              />
              <h1>WebCol</h1>
              <img
                src={Notebook}
                alt="Novo Site"
                className="notebook wow pulse"
                data-wow-delay="200ms"
              />
            </ContentHeader>
          </JobHeader>
          <ContentJob>
            <Center line>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Sistema Logístico
              </h2>
              <Wrap className="wow fadeIn" data-wow-delay="200ms">
                <p>
                  A Central de Operações Logísticas (COL) é uma aplicação que
                  proporciona as indústrias a gerir todo o processo logístico
                  desde a emissão da Nota fiscal, até a entrega ao cliente. Esse
                  projeto foi desenvolvido em parceira com nosso cliente Gera
                  Sinergia.
                </p>
                <Link to="/trabalhos">
                  <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  Voltar
                </Link>
              </Wrap>
            </Center>
          </ContentJob>
          <ContentJob gray>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Controles de Notas Fiscais
              </h2>
              <p className="wow fadeIn" data-wow-delay="200ms">
                Acompanhamento de todos os tipos de NFs desde a emissão,
                passando pelo agendamento de carga até a entrega no cliente
                final.
              </p>
              <img
                src={screen1}
                alt="Screen"
                className="wow fadeIn"
                data-wow-delay="300ms"
              />
            </Center>
            <Center>
              <h2 className="wow fadeIn" data-wow-delay="150ms">
                Rastreamento de Cargas em Tempo Real
              </h2>
              <p className="wow fadeIn" data-wow-delay="200ms">
                Através das nossas APIs as tranportadoras enviam as localizações
                de cada NF para nossos servidores.
              </p>
              <img
                src={screen2}
                alt="Screen"
                className="wow fadeIn"
                data-wow-delay="300ms"
              />
            </Center>
          </ContentJob>
          <JobHeader footer>
            <ContentHeader>
              <img
                src={ElementFooter}
                alt="Graph"
                className="element wow fadeIn"
                data-wow-delay="200ms"
              />
              <h1 className="wow fadeIn" data-wow-delay="200ms">
                Sistema Personalizável
              </h1>
              <img
                src={Notebook2}
                alt="Novo Site"
                className="notebook wow pulse"
                data-wow-delay="200ms"
              />
            </ContentHeader>
          </JobHeader>
          <Footer />
        </Content>
      </Container>
    );
  }
}
