import styled from "styled-components";
import BgServices from "../../assets/images/bg-services.png";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const InnerBanner = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: black;
  overflow: hidden;
  height: 555px;
  color: #fff;
  background-image: url(${BgServices});
`;
export const ContentBanner = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 50px;
  width: 980px;
  margin: 0 auto;
  @media screen and (max-width: 980px) {
    width: 80%;
  }
`;
export const Wrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export const Title = styled.h1`
  @keyframes typing {
    from {
      max-width: 0;
    }
    to {
      max-width: 100%;
    }
  }
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #2ad94b;
    }
  }
  font-size: 80px;
  line-height: 100px;
  font-weight: 600;
  letter-spacing: -3px;
  animation: typing 2s steps(40, end), blink-caret 1s step-end infinite;
  border-right: 4px solid;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
`;
export const Description = styled.p`
  @keyframes slideInRight {
    from {
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }
  font-size: 16px;
  color: #fff;
  max-width: 25%;
  animation: slideInRight 0.95s ease;

  @media screen and (max-width: 640px) {
    max-width: 100%;
    text-align: center;
    margin-top: 32px;
  }
`;
export const Service = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 30px;
  background: #f3f3f3;
  text-align: left;

  h1 {
    color: #272727;
    font-size: 26px;
    line-height: 32px;
    width: 60%;
    margin-bottom: 64px;
    align-self: flex-start;
  }

  @media screen and (max-width: 980px) {
    h1 {
      width: 100%;
    }
  }
`;
export const WrapContent = styled.div`
  width: 950px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .moreWorks {
    display: flex;
    padding: 0 25px;
    background: #0d4e3b;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 16px;
    transition: all 0.4s ease;
    font-weight: 600;
    font-family: "Poppins", sans-serif;

    &:hover {
      background: #001c09;
    }
  }
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;
export const WrapBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
`;
export const Ads = styled.div`
  width: 48%;
  background: #fff;
  position: relative;
  padding: 100px 54px 54px 54px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);

  h2 {
    color: #000;
    font-size: 22px;
    margin-bottom: 40px;
  }

  p {
    color: #828282;
    font-size: 16px;
    line-height: 30px;
  }
  span {
    display: block;
    width: 30%;
    height: 3px;
    background-color: #ffa929;
    position: absolute;
    left: 0;
    top: 0;
  }

  img.icon {
    position: absolute;
    left: -20%;
    top: 60%;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    img.icon {
      display: none;
    }
  }
`;
export const Project = styled(Ads)`
  margin-top: 112px;

  img.icon {
    left: 90%;
    top: -10%;
  }
  span {
    background-color: #10d6ff;
  }
  @media screen and (max-width: 980px) {
    margin-top: 32px;
  }
`;
export const ServiceDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 30px;
  background: #fff;
  text-align: left;
`;

export const WrapItems = styled(WrapBoxes)`
  padding-right: 350px;
  position: relative;

  &.web {
    padding-right: 0;
    margin-top: 64px;
    padding-top: 88px;
    border-top: 1px #d5d5d5 solid;
  }

  img.bg-service-details {
    position: absolute;
    right: -50%;
    top: 10%;
  }

  @media screen and (max-width: 980px) {
    padding-right: 0;
    img.bg-service-details {
      display: none;
    }
  }
`;

export const Item = styled.div`
  width: 45%;
  display: flex;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 72px;
  text-align: center;
  align-items: center;

  &.web {
    width: 30%;
  }

  img.icon {
    margin-bottom: 32px;
    height: 64px;
  }

  h3 {
    font-size: 18px;
    color: #202020;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    color: #828282;
    line-height: 30px;
  }
  @media screen and (max-width: 980px) {
    width: 100%;
    &.web {
      width: 100%;
    }
  }
`;
